@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* for sweetalert  */
@import url('./swal.css');

* {
  font-family: var(--font--noto-sans-tc), var(--font--noto-sans), sans-serif !important;
}

/* For Navbar */
:root {
  --browser-main-nav-height: 60px;
  --app-nav-height: 44px;
}
