.wu-toast-container .Toastify__progress-bar--info {
  @apply bg-primary-500;
}

.wu-toast-container .Toastify__progress-bar--success {
  @apply bg-green-500;
}

.wu-toast-container .Toastify__progress-bar--warning {
  @apply bg-yellow-500;
}

.wu-toast-container .Toastify__progress-bar--error {
  @apply bg-peach-500;
}

.wu-toast-container .Toastify__toast-body {
  @apply text-grayscale-800 pr-2 text-sm font-bold;
}

.wu-toast-container .Toastify__toast {
  @apply min-h-[2.875rem] px-2 py-3;
}
